<template>
  <div>
    <header class="header white-bg" v-if="shouldShowButton">
      <div>
        <i
          class="pi pi-bars cursor-pointer"
          style="font-size: 1.2rem"
          @click="this.$emit('call')"
        ></i>
      </div>
      <!--logo start-->
      <router-link :to="{ name: 'Home' }">
        <img
          src="../../assets/images/Voyado_Main-logo_black.svg"
          alt
          class="logo"
        />
      </router-link>

      <!--logo end-->
      <!-- page title start -->
      <div class="title-nav">{{ $store.state.title }}</div>
      <!-- page title end -->
      <div class="top-nav">
        <div class="nav top-menu">
          <div class="flex user-info">
            <model-select
              v-if="
                $store.state.cognitoInfo.companylist &&
                $store.state.cognitoInfo.companylist.length > 1
              "
              v-model="$store.state.cognitoInfo.companyid"
              :options="$store.state.cognitoInfo.companylist"
              placeholder="Select Company"
              id="ddlCompany"
              class="form-control ddlcompany-header mr-1"
              @update:modelValue="onCompanyChange"
              :selected="$store.state.cognitoInfo.companyid"
              :searchable="true"
            ></model-select>
            <!-- <Dropdown
              v-if="
                $store.state.cognitoInfo.companylist &&
                $store.state.cognitoInfo.companylist.length > 1
              "
              v-model="$store.state.cognitoInfo.companyid"
              :options="$store.state.cognitoInfo.companylist"
              placeholder="Select Company"
              id="ddlCompany"
              class="form-control ddlcompany-header mr-1"
              @update:modelValue="onCompanyChange"
              optionLabel="text"
              optionValue="value"
              dropdownIcon="dropdown-icon"
              editable
              @click="clearDropdownSelection"
            />-->
            <!-- current company name start -->
            <span
              v-if="
                $store.state.cognitoInfo.companylist &&
                $store.state.cognitoInfo.companylist.length == 1
              "
              class="current-company"
              >{{ $store.state.cognitoInfo.companyname }}</span
            >
            <!-- current company name end -->
            <!-- username start -->
            <span class="username-text"
              >Welcome, {{ $store.state.cognitoInfo.displayname }}</span
            >
            <!-- username end -->
            <!-- <span><i class="fa fa-user" style="font-size: 1.3rem"></i></span> -->
            <span>
              <SplitButton
                :model="items"
                class="p-button-text p-button-secondary icon"
              ></SplitButton>
            </span>
          </div>
        </div>
      </div>
    </header>
    <!--sidebar start-->
    <aside>
      <div
        id="sidebar"
        style="overflow-y: auto"
        :class="is_not_expand ? 'width-sidebar' : 'width-expand-sidebar'"
      >
        <div class="menu">
          <router-link
            style="text-decoration: none"
            :to="{ name: 'Home' }"
            id="Home"
            :class="{
              'menu-item selected': HomeTabArray.includes(CurrPath),
              'menu-item': !HomeTabArray.includes(CurrPath),
            }"
          >
            <i
              :class="{
                'fa fa-dashboard selected': HomeTabArray.includes(CurrPath),
                'fa fa-dashboard': !HomeTabArray.includes(CurrPath),
              }"
            ></i>
            <span class="menu-text">{{ lmodel?.menuDashboard }}</span>
          </router-link>
          <div
            class="menu-item cursor-pointer"
            @click="toggleMenu('deal')"
            v-bind:class="{
              'menu-item selected': DealTabArray.includes(CurrPath),
              'menu-item': !DealTabArray.includes(CurrPath),
            }"
          >
            <i
              :class="{
                'fa fa-plus selected': DealTabArray.includes(CurrPath),
                'fa fa-plus': !DealTabArray.includes(CurrPath),
              }"
            ></i>
            <span class="menu-text">{{ lmodel?.menuDealWizard }}</span>
            <i
              style="font-size: 0.6rem; margin-left: auto; margin-right: 15px"
              :class="{
                'pi pi-chevron-down': !visibleDealMenu,
                'pi pi-chevron-up': visibleDealMenu,
              }"
            ></i>
          </div>
          <!-- <router-link
            v-if="visibleDealMenu"
            :to="{ name: 'DealCreate' }"
            id="DealCreate"
            :class="{
              'menu-item1 selected': CurrPath == 'DealCreate',
              'menu-item1': CurrPath != 'DealCreate',
            }"
          >
            <span class="menu-text">Create Deal</span>
          </router-link>-->
          <router-link
            v-if="
              visibleDealMenu &&
              ($store.state.cognitoInfo.role == 'Superadmin' ||
                $store.state.cognitoInfo.role == 'Admin')
            "
            :to="{ name: 'NewDealCreate' }"
            id="NewDealCreate"
            :class="{
              'menu-item1 selected': CurrPath == 'NewDealCreate',
              'menu-item1': CurrPath != 'NewDealCreate',
            }"
          >
            <span class="menu-text">{{ lmodel?.menuNewWizardPage }}</span>
          </router-link>
          <router-link
            v-if="visibleDealMenu"
            :to="{ name: 'DealList' }"
            id="DealList"
            :class="{
              'menu-item1 selected': CurrPath == 'DealList',
              'menu-item1': CurrPath != 'DealList',
            }"
          >
            <span class="menu-text">{{ lmodel?.menuContinueFromDraft }}</span>
          </router-link>
          <div
            @click="toggleMenu('config')"
            :class="{
              'menu-item selected': ConfigTabArray.includes(CurrPath),
              'menu-item': !ConfigTabArray.includes(CurrPath),
            }"
          >
            <i
              :class="{
                'fa fa-cogs selected': ConfigTabArray.includes(CurrPath),
                'fa fa-cogs': !ConfigTabArray.includes(CurrPath),
              }"
            ></i>
            <span class="menu-text">{{ lmodel?.menuConfiguration }}</span>
            <i
              style="font-size: 0.6rem; margin-left: auto; margin-right: 15px"
              :class="{
                'pi pi-chevron-down': !visibleConfigurationMenu,
                'pi pi-chevron-up': visibleConfigurationMenu,
              }"
            ></i>
          </div>
          <router-link
            v-if="
              visibleConfigurationMenu &&
              $store.state.cognitoInfo.role != 'User'
            "
            :to="{ name: 'SiteList' }"
            id="SiteList"
            :class="{
              'menu-item1 selected':
                CurrPath == 'SiteCreate' ||
                CurrPath == 'SiteEdit' ||
                CurrPath == 'SiteList',
              'menu-item1':
                CurrPath != 'SiteCreate' ||
                CurrPath != 'SiteEdit' ||
                CurrPath != 'SiteList',
            }"
          >
            <span class="menu-text">{{ lmodel?.menuSites }}</span>
          </router-link>
          <router-link
            v-if="
              visibleConfigurationMenu &&
              $store.state.cognitoInfo.role == 'Superadmin'
            "
            :to="{ name: 'NewSiteList' }"
            id="NewSiteList"
            :class="{
              'menu-item1 selected':
                CurrPath == 'NewSiteCreate' ||
                CurrPath == 'NewSiteEdit' ||
                CurrPath == 'NewSiteList',
              'menu-item1':
                CurrPath != 'NewSiteCreate' ||
                CurrPath != 'NewSiteEdit' ||
                CurrPath != 'NewSiteList',
            }"
          >
            <span class="menu-text">New Sites</span>
          </router-link>
          <router-link
            v-if="
              visibleConfigurationMenu &&
              $store.state.cognitoInfo.role != 'User'
            "
            :to="{ name: 'CompanyList' }"
            id="CompanyList"
            :class="{
              'menu-item1 selected':
                CurrPath == 'CompanyCreate' ||
                CurrPath == 'CompanyEdit' ||
                CurrPath == 'CompanyList',
              'menu-item1':
                CurrPath != 'CompanyCreate' ||
                CurrPath != 'CompanyEdit' ||
                CurrPath != 'CompanyList',
            }"
          >
            <span class="menu-text">{{ lmodel?.menuCompany }}</span>
          </router-link>
          <a
            v-if="visibleConfigurationMenu"
            href="https://developer.voyado.com/en/onsite-messaging.html"
            target="_blank"
            id="Ho1me"
            :class="
              $store.state.activeRoute === 'Ho1me'
                ? 'menu-item selected'
                : 'menu-item'
            "
          >
            <span class="menu-text">{{ lmodel?.menuInstallation }}</span>
          </a>
          <!-- <router-link
            style="text-decoration: none"
            :to="{ name: 'ManageDealList' }"
            id="ManageDealList"
            :class="{
              'menu-item selected': ManageDealTabArray.includes(CurrPath),
              'menu-item': !ManageDealTabArray.includes(CurrPath),
            }"
          >
            <i
              :class="{
                'fa fa-tag selected': ManageDealTabArray.includes(CurrPath),
                'fa fa-tag': !ManageDealTabArray.includes(CurrPath),
              }"
            ></i>
            <span class="menu-text">{{ lmodel?.menuManageDeals }}</span>
          </router-link>-->
          <router-link
            style="text-decoration: none"
            :to="{ name: 'ManageDealListNew' }"
            id="ManageDealListNew"
            :class="{
              'menu-item selected': ManageDealTabArray.includes(CurrPath),
              'menu-item': !ManageDealTabArray.includes(CurrPath),
            }"
          >
            <i
              :class="{
                'fa fa-tag selected': ManageDealTabArray.includes(CurrPath),
                'fa fa-tag': !ManageDealTabArray.includes(CurrPath),
              }"
            ></i>
            <span class="menu-text">{{ lmodel?.menuManageDeals }}</span>
          </router-link>
          <div
            @click="toggleMenu('insight')"
            :class="{
              'menu-item selected': ReportTabArray.includes(CurrPath),
              'menu-item': !ReportTabArray.includes(CurrPath),
            }"
          >
            <i
              :class="{
                'fa fa-file-text selected': ReportTabArray.includes(CurrPath),
                'fa fa-file-text': !ReportTabArray.includes(CurrPath),
              }"
            ></i>
            <span class="menu-text">{{ lmodel?.menuInsightReports }}</span>
            <i
              style="font-size: 0.6rem; margin-left: auto; margin-right: 15px"
              :class="{
                'pi pi-chevron-down': !visibleInsightMenu,
                'pi pi-chevron-up': visibleInsightMenu,
              }"
            ></i>
          </div>
          <router-link
            v-if="visibleInsightMenu"
            :to="{ name: 'Performance' }"
            id="Performance"
            :class="{
              'menu-item1 selected': CurrPath == 'Performance',
              'menu-item1': CurrPath != 'Performance',
            }"
          >
            <span class="menu-text">{{ lmodel?.menuPerformance }}</span>
          </router-link>
          <!-- {{$route.name}}99 -->
          <router-link
            v-if="
              visibleInsightMenu &&
              $store.state.cognitoInfo.role == 'Superadmin'
            "
            :to="{ name: 'Ambassadors' }"
            id="Ambassadors"
            :class="{
              'menu-item1 selected': CurrPath == 'Ambassadors',
              'menu-item1': CurrPath != 'Ambassadors',
            }"
          >
            <span class="menu-text">{{ lmodel?.menuMyAmbassadors }}</span>
          </router-link>
          <router-link
            v-if="
              visibleInsightMenu &&
              $store.state.cognitoInfo.role == 'Superadmin'
            "
            :to="{ name: 'Friends' }"
            id="Friends"
            :class="{
              'menu-item1 selected': CurrPath == 'Friends',
              'menu-item1': CurrPath != 'Friends',
            }"
          >
            <span class="menu-text">{{ lmodel?.menuMyFriends }}</span>
          </router-link>
          <router-link
            v-if="
              visibleInsightMenu &&
              $store.state.cognitoInfo.role == 'Superadmin'
            "
            :to="{ name: 'Relations' }"
            id="Relations"
            :class="{
              'menu-item1 selected': CurrPath == 'Relations',
              'menu-item1': CurrPath != 'Relations',
            }"
          >
            <span class="menu-text">{{ lmodel?.relations }}</span>
          </router-link>
          <router-link
            v-if="$store.state.cognitoInfo.role != 'User'"
            style="text-decoration: none"
            :to="{ name: 'UserList' }"
            id="User"
            :class="{
              'menu-item selected': UserTabArray.includes(CurrPath),
              'menu-item': !UserTabArray.includes(CurrPath),
            }"
          >
            <i
              :class="{
                'fa fa-user selected': UserTabArray.includes(CurrPath),
                'fa fa-user': !UserTabArray.includes(CurrPath),
              }"
            ></i>
            <span class="menu-text">{{ lmodel?.menuUsers }}</span>
          </router-link>
          <router-link
            :to="{ name: 'Translation' }"
            id="Translation"
            :class="{
              'menu-item selected': CurrPath == 'Translation',
              'menu-item': CurrPath != 'Translation',
            }"
          >
            <i
              :class="{
                'fa fa-language selected': CurrPath == 'Translation',
                'fa fa-language': CurrPath != 'Translation',
              }"
            ></i>
            <span class="menu-text">{{ lmodel?.menuTranslation }}</span>
          </router-link>

          <div
            v-if="$store.state.cognitoInfo.role == 'Superadmin'"
            @click="toggleMenu('operation')"
            :class="{
              'menu-item selected': ContactTabArray.includes(CurrPath),
              'menu-item': !ContactTabArray.includes(CurrPath),
            }"
          >
            <i
              :class="{
                'fa fa-dashboard selected': ContactTabArray.includes(CurrPath),
                'fa fa-dashboard': !ContactTabArray.includes(CurrPath),
              }"
            ></i>
            <span class="menu-text">{{ lmodel?.menuOprationWizard }}</span>
            <i
              style="font-size: 0.6rem; margin-left: auto; margin-right: 15px"
              :class="{
                'pi pi-chevron-down': !visibleOperationDashboardMenu,
                'pi pi-chevron-up': visibleOperationDashboardMenu,
              }"
            ></i>
          </div>
          <router-link
            v-if="visibleOperationDashboardMenu"
            :to="{ name: 'FailedContact' }"
            id="FailedContact"
            :class="{
              'menu-item1 selected': CurrPath == 'FailedContact',
              'menu-item1': CurrPath != 'FailedContact',
            }"
          >
            <span class="menu-text">{{ lmodel?.menuFailContact }}</span>
          </router-link>
          <router-link
            v-if="visibleOperationDashboardMenu"
            :to="{ name: 'Error' }"
            id="Error"
            :class="{
              'menu-item1 selected': CurrPath == 'Error',
              'menu-item1': CurrPath != 'Error',
            }"
          >
            <span class="menu-text">{{ lmodel?.menuErrorSummary }}</span>
          </router-link>
          <router-link
            v-show="false"
            v-if="$store.state.cognitoInfo.role == 'Superadmin'"
            :to="{ name: 'Signupwizard' }"
            id="Signupwizard"
            :class="{
              'menu-item selected': CurrPath == 'Signupwizard',
              'menu-item': CurrPath != 'Signupwizard',
            }"
          >
            <i
              :class="{
                'fa fa-language selected': CurrPath == 'Signupwizard',
                'fa fa-language': CurrPath != 'Signupwizard',
              }"
            ></i>
            <span class="menu-text">Signup Wizard</span>
          </router-link>
          <router-link
            v-show="false"
            v-if="$store.state.cognitoInfo.role == 'Superadmin'"
            :to="{ name: 'Newdealwizard' }"
            id="Newdealwizard"
            :class="{
              'menu-item selected': CurrPath == 'Newdealwizard',
              'menu-item': CurrPath != 'Newdealwizard',
            }"
          >
            <i
              :class="{
                'fa fa-language selected': CurrPath == 'Newdealwizard',
                'fa fa-language': CurrPath != 'Newdealwizard',
              }"
            ></i>
            <span class="menu-text">Newdealwizard</span>
          </router-link>
          <router-link
            v-if="$store.state.cognitoInfo.role == 'Superadmin'"
            :to="{ name: 'ModifyDeal' }"
            id="ModifyDeal"
            :class="{
              'menu-item selected': CurrPath == 'ModifyDeal',
              'menu-item': CurrPath != 'ModifyDeal',
            }"
          >
            <i
              :class="{
                'fa fa-tag selected': CurrPath == 'ModifyDeal',
                'fa fa-tag': CurrPath != 'ModifyDeal',
              }"
            ></i>
            <span class="menu-text">Deal List</span>
          </router-link>

          <router-link
            v-if="$store.state.cognitoInfo.role == 'Superadmin'"
            :to="{ name: 'VoyadoDashboard' }"
            id="VoyadoDashboard"
            :class="{
              'menu-item selected': CurrPath == 'VoyadoDashboard',
              'menu-item': CurrPath != 'VoyadoDashboard',
            }"
          >
            <i
              :class="{
                'fa fa-dashboard selected': CurrPath == 'VoyadoDashboard',
                'fa fa-dashboard': CurrPath != 'VoyadoDashboard',
              }"
            ></i>
            <span class="menu-text">Overview</span>
          </router-link>

          <PrimeToast />
        </div>
      </div>
    </aside>
    <!-- sidebar end -->
  </div>
</template>

<script>
import DataLayerService from "@/services/data-layer.service";
import { ModelSelect } from "vue-search-select";
export default {
  components: {
    "model-select": ModelSelect,
  },
  data() {
    return {
      url: process.env.VUE_APP_MANAGER_API_URL,
      url1: "localhost:8081/",
      HomeTabArray: ["Home"],
      DealTabArray: ["DealCreate", "DealList", "DealEditNew"],
      ConfigTabArray: [
        "SiteList",
        "SiteCreate",
        "SiteEdit",
        "SiteCopy",
        "CompanyCreate",
        "CompanyList",
        "CompanyEdit",
      ],
      ManageDealTabArray: [
        "ManageDealList",
        "EditDeal",
        "CopyDeal",
        "DealLogs",
        "DealSearch",
      ],
      ReportTabArray: ["Performance", "Ambassadors", "Friends", "Relations"],
      ContactTabArray: ["Error", "FailedContact"],
      UserTabArray: ["UserList"],
      AudienceTabArray: ["Audiences", "AudienceCreate"],
      items: [
        {
          label: "Account Settings",
        },
        {
          label: "Privacy Policy",
          command: () => {
            window.open("https://voyado.com/legal/privacy-policy/", "_blank");
          },
        },
        {
          separator: true,
        },
        {
          label: "Logout",
          icon: "fa fa-sign-out",
          to: "/logout",
        },
      ],
      visibleDealMenu: false,
      visibleConfigurationMenu: false,
      visibleInsightMenu: false,
      visibleOperationDashboardMenu: false,
      lmodel: {},
    };
  },
  props: {
    is_not_expand: {
      type: Boolean,
      required: true,
    },
    shouldShowButton: {
      type: Boolean,
      required: false,
      default: true, // Default value for shouldShowButton
    },
  },
  watch: {
    "$store.state.menudataInfo": {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.getMenudata();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getData();
    this.$store.state.prevCompanyId = this.$store.state.cognitoInfo.companyid;
  },
  computed: {
    // isManagerRoute() {
    //   return (
    //     (this.$route.name &&
    //       this.$route.name.includes("https://manager.test.redeal.io/")) ||
    //     (this.$route.name &&
    //       this.$route.name.includes("localhost:8080/"))
    //   );
    // },
    CurrPath() {
      return this.$route.name;
    },
  },

  methods: {
    toggleMenu(menu) {
      if (menu === "deal") {
        this.visibleDealMenu = !this.visibleDealMenu;
        if (this.visibleDealMenu) {
          this.visibleConfigurationMenu = false;
          this.visibleInsightMenu = false;
          this.visibleOperationDashboardMenu = false;
        }
      } else if (menu === "config") {
        this.visibleConfigurationMenu = !this.visibleConfigurationMenu;
        if (this.visibleConfigurationMenu) {
          this.visibleDealMenu = false;
          this.visibleInsightMenu = false;
          this.visibleOperationDashboardMenu = false;
        }
      } else if (menu === "insight") {
        this.visibleInsightMenu = !this.visibleInsightMenu;
        if (this.visibleInsightMenu) {
          this.visibleDealMenu = false;
          this.visibleConfigurationMenu = false;
          this.visibleOperationDashboardMenu = false;
        }
      } else if (menu === "operation") {
        this.visibleOperationDashboardMenu =
          !this.visibleOperationDashboardMenu;
        if (this.visibleOperationDashboardMenu) {
          this.visibleDealMenu = false;
          this.visibleInsightMenu = false;
          this.visibleConfigurationMenu = false;
        }
      }
    },
    async getData() {
      const _userInfo = await this.$store.state.cognitoInfo;
      if (_userInfo && Object.keys(_userInfo).length !== 0) {
        if (
          this.$store &&
          this.$store.state &&
          this.$store.state.menudataInfo &&
          Object.keys(this.$store.state.menudataInfo).length !== 0
        ) {
          this.lmodel = this.$store.state.menudataInfo;
        } else {
          await DataLayerService.get("/home/GetMenuData").then(
            async (result) => {
              await this.$store.commit("setMenudataInfo", result);
            }
          );
          this.lmodel = this.$store.state.menudataInfo;
        }
      }
    },
    getMenudata() {
      if (
        this.$store &&
        this.$store.state &&
        this.$store.state.menudataInfo &&
        Object.keys(this.$store.state.menudataInfo).length !== 0
      ) {
        this.lmodel = this.$store.state.menudataInfo;
      }
    },
    routeTabclick(id) {
      if (id != 1) {
        var a_Deal = this.$refs.a_Deal;
        if (
          !window.$(a_Deal).hasClass("menu-item") &&
          !window.$(a_Deal).hasClass("menu-item selected")
        ) {
          window.$(a_Deal).click();
        }
      }
      if (id != 2) {
        var a_Configuration = this.$refs.a_Configuration;
        if (
          !window.$(a_Configuration).hasClass("menu-item") &&
          !window.$(a_Configuration).hasClass("menu-item selected")
        ) {
          window.$(a_Configuration).click();
        }
      }
      if (id != 3) {
        var a_ManageDeals = this.$refs.a_ManageDeals;
        if (
          !window.$(a_ManageDeals).hasClass("menu-item") &&
          !window.$(a_ManageDeals).hasClass("menu-item selected")
        ) {
          window.$(a_ManageDeals).click();
        }
      }
      if (id != 4) {
        var a_Reports = this.$refs.a_Reports;
        if (
          !window.$(a_Reports).hasClass("menu-item") &&
          !window.$(a_Reports).hasClass("menu-item selected")
        ) {
          window.$(a_Reports).click();
        }
      }
      if (id != 5) {
        var a_Contact = this.$refs.a_Contact;
        if (
          !window.$(a_Contact).hasClass("menu-item") &&
          !window.$(a_Contact).hasClass("menu-item selected")
        ) {
          window.$(a_Contact).click();
        }
      }
    },
    ShowHideSidebar() {
      const body = this.$refs.body;
      if (body) {
        body.classList.toggle("is-sidebar-nav-open");
      }
    },
    onCompanyChange(item) {
      if (this.$store.state.prevCompanyId != item) {
        this.$store.commit("replaceCompanyId", item);
        var routeName = this.$route.name;
        if (routeName == "SiteEdit" || routeName == "SiteCreate") {
          this.$router.push({ name: "SiteList" });
        } else if (routeName == "DealEditNew" || routeName == "DealCreate") {
          this.$router.push({ name: "DealList" });
        } else if (routeName == "EditDeal") {
          this.$router.push({ name: "ManageDealList" });
        } else if (routeName == "UserEdit" || routeName == "UserCreate") {
          this.$router.push({ name: "UserList" });
        } else {
          if (routeName == "Home") this.$store.commit("resetDashboardLastSync");
          this.$router.go();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/headerbar.scss";

::v-deep(.dashboard-style) {
  .fa {
    color: #4cb87d !important;
  }
}

:deep(.icon) {
  .pi-chevron-down:before {
    content: url("../../assets/images/user-icon.png");
    color: #21211f !important;
  }
}
:deep(.p-dropdown) {
  height: calc(1.5em + 0.75rem + 7px);
  width: 360px;
  line-height: 1.5;
  font-size: 14px;
  border: 1px solid #c0c0bc;
  border-radius: 0.25rem;
  color: #777 !important;
  text-decoration: none;
  box-shadow: none;
  background: none;
}
.ui.selection.dropdown {
  min-height: 14px;
  height: calc(1.5em + 0.75rem + 7px);
  width: 360px;
  line-height: 1.5;
  font-size: 14px;
  border: 1px solid #c0c0bc;
  border-radius: 0.25rem;
  color: #777 !important;
  text-decoration: none;
  box-shadow: none;
  background: none;
}
</style>
