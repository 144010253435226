<template>
  <div>
    <div class="card spinner" v-if="$store.state.loader > 0">
      <ProgressSpinner />
    </div>
    <section id="container">
      <HeaderSideBar
        :is_not_expand="is_not_expand"
        @call="expand()"
        :shouldShowButton="shouldShowButton"
        v-if="show_sidebar"
      />
      <div
        :class="
          is_not_expand ||
          $route.name == 'Login' ||
          $route.name == 'ForgotPassword' ||
          $route.name == 'ResetPassword' ||
          $route.name == 'ChangePassword' ||
          $route.name == 'VoyadoDashboard' ||
          $route.name == 'ManageDealUpdateById' ||
          $route.name == 'copydeal' ||
          $route.name == 'ManageDealUpdateCreate'
            ? 'not-expand'
            : 'expand'
        "
      >
        <router-view></router-view>
      </div>
      <PrimeToast />
      <section
        id="main-content"
        v-if="
          $route.name !== 'Login' &&
          $route.name !== 'ForgotPassword' &&
          $route.name !== 'ResetPassword' &&
          $route.name !== 'ChangePassword' &&
          $route.name !== 'ManageDealUpdateById' &&
          $route.name !== 'ManageDealUpdateCreate' &&
          showFooter
        "
      >
        <footer class="site-footer">
          <div class="text-center">
            {{ new Date().getFullYear() }} &copy; Voyado AB
          </div>
        </footer>
      </section>
      <section
        id="footer-change"
        v-if="
          ($route.name == 'ManageDealUpdateById' ||
            $route.name == 'ManageDealUpdateCreate') &&
          showFooter
        "
      >
        <footer class="site-footer">
          <div class="text-center">
            {{ new Date().getFullYear() }} &copy; Voyado AB
          </div>
        </footer>
      </section>
    </section>
    <PrimeToast />
    <!-- <section id="container" v-if="$route.name == 'ManageDealUpdateById'">
      <ManageDealUpdate />
    </section>-->
    <!-- <section id="container" v-if="$route.name == 'ManageDealUpdateCreate'">
      <ManageDealAdd />
    </section>
    <section id="container" v-if="$route.name == 'VoyadoDashboard'">
      <VoyadoDashboard />
    </section>-->
  </div>
</template>
<script>
import HeaderSideBar from "@/components/layout/HeaderSidebar.vue";
// import ManageDealAdd from "@/views/ManageDealUpdate/index.vue";
// import ManageDealUpdate from "@/views/ManageDealUpdate/index.vue";
// import VoyadoDashboard from "@/views/VoyadoDashboard/voyadodashboard.vue";

export default {
  components: {
    HeaderSideBar,
    // ManageDealAdd,
    // ManageDealUpdate,
    // VoyadoDashboard,
  },
  data() {
    return {
      is_not_expand: false,
      showParam: null,
      shouldShowButton: true,
      changeRef: false,
      show_sidebar: false,
      showFooter: false,
    };
  },
  watch: {
    "$store.state.loader": {
      handler(newValue) {
        document.querySelector("body").style.overflow =
          newValue > 0 ? "hidden" : "auto";
      },
      immediate: true,
    },
    $route() {
      this.changeRef = false;
    },
  },
  // mounted() {
  //   this.$router.beforeEach(() => {
  //     this.changeRef = false;
  //   });
  // const queryParams = new URLSearchParams(window.location.search);
  // const showParam = queryParams.get("show");
  // const hideMenu = queryParams.get("hide");
  // const newQuery = {};

  // queryParams.forEach((value, key) => {
  //   newQuery[key] = value;
  // });

  // if (showParam === "deallistnew") {
  //   this.$router.push({ name: "ManageDealList", query: newQuery });
  //   this.expand();
  //   this.shouldShowButton = false;
  // } else if (showParam === "deallist" || showParam === "deallis") {
  //   this.$router.push({
  //     name: "ManageDealListNew",
  //     query: newQuery,
  //   });
  //   this.expand();
  //   this.shouldShowButton = false;
  // } else if (hideMenu === "menu") {
  //   this.expand();
  //   this.shouldShowButton = false;
  // }
  // },
  updated() {
    if (
      this.$route.name !== undefined &&
      this.$route.name !== null &&
      this.$route.name !== "" &&
      this.changeRef == false
    ) {
      const queryParams = new URLSearchParams(window.location.search);
      const showParam = queryParams.get("show");
      const hideMenu = queryParams.get("hide");
      const newQuery = {};

      queryParams.forEach((value, key) => {
        newQuery[key] = value;
      });

      if (showParam === "deallistnew") {
        this.$router.push({ name: "ManageDealList", query: newQuery });
        this.expand();
        this.shouldShowButton = false;
      } else if (showParam === "deallist" || showParam === "deallis") {
        if (this.$route.name !== "ManageDealListNew") {
          this.$router.push({
            name: "ManageDealListNew",
            query: newQuery,
          });
          this.is_not_expand = false;
          this.expand();
          this.shouldShowButton = false;
        } else {
          this.is_not_expand = false;
          this.expand();
          this.shouldShowButton = false;
        }
      } else if (hideMenu === "menu") {
        this.expand();
        this.shouldShowButton = false;
      }
      if (!this.is_not_expand) {
        this.show_sidebar = true;
      } else {
        this.show_sidebar = false;
      }
      if (
        this.$route.name == "Login" ||
        this.$route.name == "ForgotPassword" ||
        this.$route.name == "ResetPassword" ||
        this.$route.name == "ChangePassword" ||
        this.$route.name == "VoyadoDashboard" ||
        this.$route.name == "ManageDealUpdateById" ||
        this.$route.name == "copydeal" ||
        this.$route.name == "ManageDealUpdateCreate"
      ) {
        this.show_sidebar = false;
      }
      this.showFooter = true;
      this.changeRef = true;
    }
  },

  methods: {
    expand() {
      this.is_not_expand = !this.is_not_expand;
    },
  },
};
</script>
<style scoped>
.expand {
  margin-left: 210px;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
}
.not-expand {
  margin-left: 0px;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
}
</style>
